// This code needs to be supported from IE9 onwards as long as it's loaded before the polyfills.
const cookieName = 'oldBrowserMsg';

export const getCookieString = (cookieName) => {
    const d = new Date();
    d.setMonth(d.getMonth() + 12 * 1); // 1 year
    return cookieName + '=1;path=/;expires=' + d.toUTCString();
};

export const showOldBrowserMessage = () => {
    const el = document.getElementById('old-browser-msg');
    const closeBtn = document.getElementById('close-old-browser-msg');
    closeBtn.onclick = () => {
        el.style.display = 'none';
        document.cookie = getCookieString(cookieName);
    };
    el.style.display = 'block';
};

// Excluded browsers are IE11 and Edge (Business decision, September 2018)
const excludedBrowser = () => (navigator.userAgent.indexOf('Trident/7.0') > 0) || (navigator.userAgent.indexOf('Edge/') > 0);

const cookiePresent = (cookie) => document.cookie.match('(^|;) ?' + cookie + '=([^;]*)(;|$)');

if (!excludedBrowser() && !cookiePresent(cookieName)) {
    document.addEventListener('DOMContentLoaded', showOldBrowserMessage);
}
